import { Routes } from '@angular/router';
import { loggedIn } from '../guards/auth.guard';
import { ProfileDetailsComponent } from '../profile/profile-details/profile-details.component';
import { ProfileComponent } from '../profile/profile.component';
import { BasicInfoComponent } from '../registration/basic-info/basic-info.component';
import { DietNutritionComponent } from '../registration/diet-nutrition/diet-nutrition.component';
import { FinancialPlanningComponent } from '../registration/financial-planning/financial-planning.component';
import { LifestyleHealthComponent } from '../registration/lifestyle-health/lifestyle-health.component';
import { MoreInfoComponent } from '../registration/more-info/more-info.component';
import { RegistrationComponent } from '../registration/registration.component';
import { UploadResumeComponent } from '../registration/upload-resume/upload-resume.component';
import { WorkSkillsComponent } from '../registration/work-skills/work-skills.component';

export const profileRoutes: Routes = [
  {
    path: 'profile',
    canActivate: [loggedIn],
    component: ProfileComponent,
    children: [
      { path: 'details', component: ProfileDetailsComponent },
      {
        path: 'edit',
        component: RegistrationComponent,
        children: [
          {
            path: 'basic-info',
            component: BasicInfoComponent,
            data: { animation: 'BasicInfo' },
          },
          {
            path: 'work-skills',
            component: WorkSkillsComponent,
            data: { animation: 'WorkSkills' },
          },
          {
            path: 'upload-resume',
            component: UploadResumeComponent,
            data: { animation: 'UploadResume' },
          },
          {
            path: 'lifestyle-health',
            component: LifestyleHealthComponent,
            data: { animation: 'LifestyleHealth' },
          },
          {
            path: 'diet-nutrition',
            component: DietNutritionComponent,
            data: { animation: 'DietNutrition' },
          },
          {
            path: 'financial-planning',
            component: FinancialPlanningComponent,
            data: { animation: 'FinancialPlanning' },
          },
          {
            path: 'more-info',
            component: MoreInfoComponent,
            data: { animation: 'MoreInfo' },
          },
          // Redirect 'edit' path to 'basic-info' as default
          { path: '', redirectTo: 'basic-info', pathMatch: 'full' },
          // Fallback for unknown routes
          { path: '**', redirectTo: 'basic-info' },
        ],
      },
      { path: '', redirectTo: 'details', pathMatch: 'full' },
    ],
  },
];
