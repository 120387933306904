<!-- eslint-disable @angular-eslint/template/prefer-self-closing-tags -->
<!-- eslint-disable @angular-eslint/template/prefer-control-flow -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<!-- Profile Page Layout similar to Dashboard -->
<mat-toolbar class="main-toolbar">
  <!-- Logo on the left -->
  <div i18n class="logo" style="font-weight: 600">Easify</div>

  <!-- Spacer to push content to the right -->
  <span class="spacer"></span>

  <!-- User Avatar with Initials and Dropdown Icon -->
  <div class="user-profile" [matMenuTriggerFor]="profileMenu">
    <div class="avatar-circle">
      <span i18n class="initials">JD</span>
      <!-- Replace 'JD' with dynamic initials -->
    </div>
    <mat-icon i18n>keyboard_arrow_down</mat-icon>
  </div>

  <!-- User profile dropdown menu -->
  <mat-menu #profileMenu="matMenu">
    <button i18n mat-menu-item (click)="logout()">Sign Out</button>
    <button i18n mat-menu-item (click)="navigateTo('/dashboard/profile')">
      Profile
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <!-- Sidenav Section -->
  <mat-sidenav
    #sidenav
    class="sidenav"
    [mode]="sidenavMode"
    [opened]="!isMinimized || !isMobile"
    [style.width]="isMobile ? '100%' : '250px'"
  >
    <mat-nav-list>
      <button mat-button>
        <mat-icon svgIcon="calendar" />
        <span i18n>Profile Details</span>
      </button>
      <button mat-button>
        <mat-icon svgIcon="book" />
        <span i18n>Security</span>
      </button>
      <button mat-button>
        <mat-icon svgIcon="monitor" />
        <span i18n>Notifications</span>
      </button>
      <button mat-button>
        <mat-icon svgIcon="chef-hat" />
        <span i18n>General</span>
      </button>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Main Content Section -->
  <mat-sidenav-content>
    <div class="content" style="min-height: 800px">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
