import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { EasifyService } from '../services/easify.service';
import { ScheduleService } from '../services/schedule.service';
import { AppState } from '../store/app.state';
import { setLoading } from '../store/loader/loading.actions';
import {
  loadScheduleFailure,
  loadScheduleSuccess,
} from '../store/schedule/schedule.actions';
import { selectSchedule } from '../store/schedule/schedule.selectors';

export const scheduleGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store<AppState>);
  const scheduleService = inject(ScheduleService);
  const chatService = inject(EasifyService);

  return store.select(selectSchedule).pipe(
    take(1),
    switchMap((schedule) => {
      store.dispatch(setLoading(true));
      const today = new Date().toLocaleDateString('en-CA');

      if (schedule && schedule?.id === today) {
        store.dispatch(setLoading(false));
        return of(true);
      } else {
        return scheduleService.getTodaySchedule(today).pipe(
          switchMap((schedules) => {
            if (schedules.length > 0) {
              const scheduleData = schedules[0];
              store.dispatch(loadScheduleSuccess({ scheduleData }));
              store.dispatch(setLoading(false));
              return of(true);
            } else {
              return chatService.getDaily().pipe(
                map(() => {
                  store.dispatch(setLoading(false));
                  return true;
                }),
              );
            }
          }),
          catchError((error) => {
            store.dispatch(loadScheduleFailure({ error }));
            console.error('Error loading schedule:', error);
            router.navigate(['/error']);
            return of(false);
          }),
        );
      }
    }),
  );
};
