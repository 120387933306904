/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/use-unknown-in-catch-callback-variable */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  query,
  where,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private router: Router,
  ) {}

  getTodaySchedule(today: any): Observable<any> {
    if (!this.auth.currentUser) {
      this.router.navigate(['/signup']);
      throw new Error('User not authenticated');
    }

    const userRef = doc(this.firestore, 'users', this.auth.currentUser.uid);
    const schedulesCollectionRef = collection(userRef, 'schedules');

    // Query to find today's schedule
    const todayQuery = query(schedulesCollectionRef, where('id', '==', today));

    return collectionData(todayQuery, { idField: 'id' }) as Observable<any>;
  }
}
