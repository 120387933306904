import { Routes } from '@angular/router';
import { guestUser, loggedIn } from './guards/auth.guard';
import { scheduleGuard } from './guards/schedule.guard';
import { profileRoutes } from './routes/profile.routes';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./components/login/login.component').then(
        (m) => m.LoginComponent,
      ),
    canActivate: [guestUser],
  },
  ...profileRoutes,
  {
    path: 'signup',
    loadComponent: () =>
      import('./registration/register/register.component').then(
        (m) => m.RegisterComponent,
      ),
    canActivate: [guestUser],
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (m) => m.DashboardComponent,
      ),
    canActivate: [loggedIn],
    children: [
      {
        path: 'dailylook',
        loadComponent: () =>
          import('./components/daily-look/daily-look.component').then(
            (m) => m.DailyLookComponent,
          ),
        canActivate: [scheduleGuard],
      },
      {
        path: 'course/:id',
        loadComponent: () =>
          import(
            './components/courses/course-details/course-details.component'
          ).then((m) => m.CourseDetailsComponent),
      },
      {
        path: 'recipe/:id',
        loadComponent: () =>
          import(
            './components/recipes/recipe-details/recipe-details.component'
          ).then((m) => m.RecipeDetailsComponent),
      },
      {
        path: 'workout/:id',
        loadComponent: () =>
          import(
            './components/fitness/workout-details/workout-details.component'
          ).then((m) => m.WorkoutDetailsComponent),
      },
      {
        path: 'financial-plan/:id',
        loadComponent: () =>
          import(
            './components/financial/financial-plan-details/financial-plan-details.component'
          ).then((m) => m.FinancialPlanDetailsComponent),
      },
      {
        path: 'newRecipe',
        loadComponent: () =>
          import(
            './components/recipes/recipe-details/recipe-details.component'
          ).then((m) => m.RecipeDetailsComponent),
      },
      {
        path: 'personal-growth',
        loadComponent: () =>
          import('./components/personal-growth/personal-growth.component').then(
            (m) => m.PersonalGrowthComponent,
          ),
      },
      {
        path: 'resume-upload',
        loadComponent: () =>
          import(
            './components/personal-growth/resume-upload/resume-upload.component'
          ).then((m) => m.ResumeUploadComponent),
      },
      {
        path: 'courses',
        loadComponent: () =>
          import('./components/courses/courses.component').then(
            (m) => m.CoursesComponent,
          ),
      },
      {
        path: 'recipes',
        loadComponent: () =>
          import('./components/recipes/recipes.component').then(
            (m) => m.RecipesComponent,
          ),
      },
      {
        path: 'financial',
        loadComponent: () =>
          import('./components/financial/financial.component').then(
            (m) => m.FinancialComponent,
          ),
      },
      {
        path: 'recipe-by-ingredients',
        loadComponent: () =>
          import(
            './components/recipes/recipe-by-ingredients/recipe-by-ingredients.component'
          ).then((m) => m.RecipeByIngredientsComponent),
      },
      {
        path: 'shopping/:id',
        loadComponent: () =>
          import(
            './components/recipes/shopping-list/shopping-list.component'
          ).then((m) => m.ShoppingListComponent),
      },
      {
        path: 'fitness',
        loadComponent: () =>
          import('./components/fitness/fitness.component').then(
            (m) => m.WorkoutsComponent,
          ),
      },
      { path: '**', redirectTo: 'dailylook' }, // Fallback route
    ],
  },
  { path: '**', redirectTo: 'login' }, // Fallback route for all other paths
];
