/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { createReducer, on } from '@ngrx/store';
import {
  loadSchedule,
  loadScheduleFailure,
  loadScheduleSuccess,
  refreshSchedule,
  refreshScheduleFailure,
  refreshScheduleSuccess,
} from './schedule.actions';

export interface ScheduleState {
  schedule: any | null; // Adjust the type according to your actual schedule structure
  error: any | null;
  loading: boolean; // New loading property to track state
}

export const initialState: ScheduleState = {
  schedule: null,
  error: null,
  loading: false, // Initialize loading as false
};

export const scheduleReducer = createReducer(
  initialState,

  // Trigger loading for loadSchedule
  on(loadSchedule, (state) => ({
    ...state,
    schedule: null,
    error: null,
    loading: true, // Set loading to true when starting to load schedule
  })),

  // Set schedule and stop loading on load success
  on(loadScheduleSuccess, (state, { scheduleData }) => ({
    ...state,
    schedule: scheduleData,
    loading: false, // Set loading to false when load succeeds
  })),

  // Set error and stop loading on load failure
  on(loadScheduleFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false, // Set loading to false when load fails
  })),

  // Trigger loading for refreshSchedule
  on(refreshSchedule, (state) => ({
    ...state,
    error: null,
    loading: true, // Set loading to true when starting to refresh schedule
  })),

  // Set schedule and stop loading on refresh success
  on(refreshScheduleSuccess, (state, { scheduleData }) => ({
    ...state,
    schedule: scheduleData,
    loading: false, // Set loading to false when refresh succeeds
  })),

  // Set error and stop loading on refresh failure
  on(refreshScheduleFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false, // Set loading to false when refresh fails
  })),
);
