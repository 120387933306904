import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { EasifyService } from '../../services/easify.service';
import { ScheduleService } from '../../services/schedule.service'; // Import ScheduleService to fetch the updated schedule
import {
  refreshSchedule,
  refreshScheduleFailure,
  refreshScheduleSuccess,
} from './schedule.actions';

@Injectable()
export class ScheduleEffects {
  constructor(
    private actions$: Actions,
    private chatService: EasifyService,
    private scheduleService: ScheduleService,
  ) {}

  refreshSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshSchedule), // Listening for refreshSchedule action
      switchMap(() => {
        // Call getDaily to trigger schedule generation
        return this.chatService.getDaily().pipe(
          // Once getDaily succeeds, fetch the updated schedule from the database
          switchMap(() => {
            const today = new Date().toLocaleDateString('en-CA');
            return this.scheduleService.getTodaySchedule(today).pipe(
              map((schedules) => {
                if (schedules.length > 0) {
                  // On success, dispatch refreshScheduleSuccess and stop loading
                  return refreshScheduleSuccess({ scheduleData: schedules[0] });
                } else {
                  // If no schedule is found, dispatch a failure action
                  return refreshScheduleFailure({ error: 'No schedule found' });
                }
              }),
              catchError((error) => {
                console.error('Error fetching schedule from DB:', error);
                // On failure, dispatch refreshScheduleFailure and stop loading
                return of(refreshScheduleFailure({ error }));
              }),
            );
          }),
          catchError((error) => {
            console.error('Error refreshing schedule:', error);
            // On failure, dispatch refreshScheduleFailure and stop loading
            return of(refreshScheduleFailure({ error }));
          }),
        );
      }),
    ),
  );
}
