<!-- Headline -->
<h5 i18n>upload Resume</h5>

<!-- Sub-header -->
<p class="sub-header" i18n>
  Your resume helps us understand your professional background, allowing us to
  provide personalized advice and recommendations tailored to your career growth
  and goals.
</p>
<app-resume-upload (resumeUploaded)="onResumeUploaded()" />
