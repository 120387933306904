<!-- eslint-disable @angular-eslint/template/button-has-type -->
<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<!-- eslint-disable @angular-eslint/template/prefer-control-flow -->
<form [formGroup]="dietNutritionForm" (ngSubmit)="onSubmit()">
  <!-- Headline -->
  <h5 i18n>Diet & Nutrition</h5>

  <!-- Sub-header -->
  <p class="sub-header" i18n>
    Your diet and nutrition choices help us deliver personalized tips and advice
    to support your health goals.
  </p>

  <!-- Food Restrictions Field -->
  <div>
    <h6 i18n>Do you have any food restrictions?</h6>
    <p class="subtext" i18n>Please select all that apply</p>

    <!-- Food Restriction Checkboxes -->
    <mat-checkbox formControlName="vegetarian" i18n>Vegetarian</mat-checkbox>
    <mat-checkbox formControlName="vegan" i18n>Vegan</mat-checkbox>
    <mat-checkbox formControlName="lactoseIntolerant" i18n>
      Lactose Intolerant
    </mat-checkbox>
    <mat-checkbox formControlName="glutenFree" i18n>Gluten Free</mat-checkbox>
  </div>

  <!-- Instruction Text for Nutrition Tags -->
  <h6 i18n>What cuisines do you like?</h6>
  <!-- Nutrition Autocomplete -->
  <app-cuisine-autocomplete (cuisineAdded)="onCategoryAdded($event)" />

  <!-- Added Nutrition Tags as Chips -->
  <div>
    <mat-chip-set>
      <mat-chip
        *ngFor="let tag of addedNutritionCategories"
        [removable]="true"
        (removed)="removeCategory(tag)"
      >
        {{ tag }}
        <mat-icon i18n matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
  </div>

  <!-- Instruction Text for Nutrition Tags -->
  <h6 i18n>What are some of your favorite things to eat?</h6>
  <!-- Nutrition Autocomplete -->
  <app-recipe-tag-autocomplete (tagAdded)="onRecipeTagAdded($event)" />

  <!-- Added Nutrition Tags as Chips -->
  <div>
    <mat-chip-set>
      <mat-chip
        *ngFor="let tag of addedRecipeTags"
        [removable]="true"
        (removed)="removeRecipeTag(tag)"
      >
        {{ tag }}
        <mat-icon i18n matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
  </div>

  <!-- Submit Button -->
  <div class="submit-btn">
    <button
      mat-raised-button
      color="primary"
      [disabled]="dietNutritionForm.invalid"
      i18n
    >
      Continue
    </button>
  </div>
</form>
