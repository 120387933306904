import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  selectedTab = 'details'; // Default selected tab
  isMinimized = false; // To track whether sidenav is minimized
  sidenavMode: 'side' | 'over' = 'side'; // Initial sidenav mode is 'side'
  isMobile = false; // Tracks if the device is mobile

  constructor(
    private authService: AuthService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    // Observe screen size changes and adjust sidenav mode
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches; // True if the screen is mobile size

        // Set sidenav mode to 'over' if mobile, otherwise 'side'
        this.sidenavMode = this.isMobile ? 'over' : 'side';
      });
  }

  // Function to navigate to a specific route (e.g., dashboard sections)
  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  // Function to toggle the sidebar visibility
  toggleSidebar(): void {
    this.isMinimized = !this.isMinimized;
  }

  // Function to switch between profile tabs (details, security, etc.)
  selectTab(tab: string): void {
    this.selectedTab = tab;
  }

  // Function to log the user out
  logout(): void {
    this.authService.logout().subscribe({
      next: () => {
        this.router.navigate(['/']);
      },
      error: (error) => {
        console.error('Logout error:', error);
      },
    });
  }
}
